import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrarseComponent } from './registrarse.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { UserModule } from './user/user.module';
import { FleteModule } from './flete/flete.module';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [RegistrarseComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsModule,
    UserModule,
    FleteModule,

  ],
  exports: [
    RegistrarseComponent
  ]
})
export class RegistrarseModule { }
