// Definición de tipos para mayor claridad
export type Perfil = 'Usuario' | 'Fletero' | 'Admin';
export type TipoVehiculo =   'Camioneta' | 
'Camion' | 
'Grua' | 
'Furgonetas' | 
'Camiones frigoríficos' | 
'Trailer' | 
'Cisterna' |  // Para transporte de líquidos
'Portacontenedores' |  // Camiones que transportan contenedores
'Vehículo de carga pesada' ;  // Para cargas planas como maquinaria ;


const tiposValidos: TipoVehiculo[] = [
  'Camioneta',
  'Camion',
  'Grua',
  'Furgonetas',
  'Camiones frigoríficos',
  'Trailer',
  'Cisterna',
  'Portacontenedores',
  'Vehículo de carga pesada'
];

// Constantes para opciones
export const provincias = [ 'Buenos Aires', 'Catamarca', 'Chaco', 'Chubut', 'Córdoba', 'Corrientes', 'Entre Ríos', 'Formosa', 'Jujuy',
  'La Pampa', 'La Rioja', 'Mendoza', 'Mendoza', 'Misiones', 'Neuquén', 'Río Negro', 'Salta', 'San Juan', 'Santa Cruz', 'Santa Fe',
   'Santiago del Estero', 'Tierra del Fuego', 'Tucumán'];
 
 export const hora = ['5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
 export const minutos = ['00', '15', '30', '45'];
 export const tipoVehiculo = [  'Camioneta',
  'Camion',
  'Grua',
  'Furgonetas',
  'Camiones frigoríficos',
  'Trailer',
  'Cisterna',
  'Portacontenedores',
  'Vehículo de carga pesada'];
 export const ayudantes = ['Sin ayudantes', '+1 ayudantes', '+2 ayudantes', '+3 ayudantes'];
 

// Interfaz base para atributos comunes
export interface UserBase {
  uid: string;
  nombre: string;
  apellido: string;
  dni: string;
  edad: number;
  domicilio: string;
  telefono: string;
  image: string;
  email: string;
  password: string;
  perfil: Perfil; // Tipo de perfil
  codeVeri?: string;
  // provincia?: provincias
}

// Interfaz específica para "Usuario"
export interface UserU extends UserBase {
  perfil: 'Usuario'; // Limita el perfil a 'Usuario'
}

// Interfaz específica para "Fletero"
export interface UserF extends UserBase {
  perfil: 'Fletero'; // Limita el perfil a 'Fletero'
  datosVehiculos: datosVehiculo;
  verificado: boolean;
  habilitado: boolean;
  recomendacion: number;
  usuariosRecomendados?: string[]; 
}

// Interfaz específica para "Admin"
export interface UserA extends UserBase {
  perfil: 'Admin'; // Limita el perfil a 'Admin'
  // Puedes agregar atributos específicos para el administrador si lo necesitas
}

// Interfaz para detalles del vehículo
export interface datosVehiculo {
  uid: string;
  tipoVehiculo: TipoVehiculo;
  marca: string;
  ano: string;
  modelo: string;
  patente: string;
  imagePatente: string; 
  imageDni: string; 
  imageCarnet: string; 
  imageDniDorzal: string;
  imageCarnetDorzal: string;
}

// Interfaz para detalles del flete
export interface DatosFlete {
  id: string;
  nombre: string;
  apellido: string;
  fecha: string;
  hora: number;
  minutos: number;
  startCoordinates?: { latitude: number; longitude: number };
  endCoordinatesP?: { latitude: number; longitude: number };
  uDesde: string;
  uHasta: string;
  km?: number;
  cargamento: string;
  tipoVehiculo: TipoVehiculo;
  ayudantes: 'Sin ayudantes' | '+1 ayudantes' | '+2 ayudantes' | '+3 ayudantes';
  uid: string;
  tiempoTranscurrido?: string;
  precio: number;
  visible?: { [fleteroId: string]: boolean };
  image?: string;
  timestamp?: Date;
}

// Interfaz para la respuesta de un flete
export interface respuesta {
  id: string;
  idFletero: string;
  nombre: string;
  apellido: string;
  precio: number; 
  telefono: string;
  mensaje: string;
  precioEnviado: boolean; 
  recomendado?: boolean; 
}

// Interfaz para opiniones
export interface Opiniones {
  id: string;
  nombre: string;
  apellido: string;
  mensaje: string;
  perfil: 'Usuario' | 'Fletero';
}

