export const environment = {
  production: false,
  apiKey: 'pk.eyJ1Ijoic2FudGlkaWF6b2siLCJhIjoiY2xtYXA4dzJsMHFldjNybDYxc2t4NGtkYyJ9.GFmsDnWhdE7-otdjZC3twQ',
};

export const environment2 = {
  production: false,  // Debe ser false para desarrollo
  firebaseConfig: {
    apiKey: "AIzaSyCTkoutvr1XdOFJv_CjvxDpa2zkgAURmwA",
    authDomain: "fletesya-c31eb.firebaseapp.com",
    projectId: "fletesya-c31eb",
    storageBucket: "fletesya-c31eb.appspot.com",
    messagingSenderId: "551202844478",
    appId: "1:551202844478:web:c91f6790a91fa8e6bb066f",
    measurementId: "G-ML86C3TGP7"
  }
};
