import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserF, UserU } from './folder/models/models';
import { AuthService } from './folder/services/auth.service';
import { FirestoreService } from './folder/services/firestore.service';
import { InteractionService } from './folder/services/interaction.service';
import { environment } from '../environments/environment'; // Asegúrate de que esta ruta sea correcta

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  login: boolean = false;
  rol: 'Usuario' | 'Fletero' | 'Admin' = null;

  constructor(
    private auth: AuthService,
    private router: Router,
    private interaction: InteractionService,
    private firestore: FirestoreService
  ) {
    this.auth.stateUser().subscribe(res => {
      if (res) {
        this.login = true;
        this.getDatosUser(res.uid);
        this.getDatosFletero(res.uid);
      } else {
        console.log('no está logeado');
        this.login = false;
      }
    });
  }

  ngOnInit() {
    // Solo detecta DevTools si no está en producción
    if (!environment.production) {
      this.detectDevTools();
    }
  }

  logout() {
    this.auth.logout();
  }

  getDatosUser(uid: string) {
    const path = 'Usuarios';
    const id = uid;
    this.firestore.getDoc<UserU>(path, id).subscribe(res => {
      if (res) {
        this.rol = res.perfil;
      }
    });
  }

  getDatosFletero(uid: string) {
    const path = 'Fleteros';
    const id = uid;
    this.firestore.getDoc<UserF>(path, id).subscribe(res => {
      if (res) {
        this.rol = res.perfil;
      }
    });
  }

  private detectDevTools(): void {
    let devtoolsOpen = false;

    const devtools = /./;

    // Sobrescribe el método toString
    devtools.toString = function (): string {
      if (!devtoolsOpen) {
        console.log('DevTools están abiertas');
        alert('DevTools están abiertas. Algunas funcionalidades pueden estar deshabilitadas.');
        devtoolsOpen = true; // Marcar como abierto
      }
      return ''; // Devuelve un string vacío para cumplir con el tipo
    };

    // Revisa periódicamente si las DevTools están abiertas
    const checkDevTools = setInterval(() => {
      console.log(devtools); // Llama al toString
      if (devtoolsOpen) {
        console.log('Las DevTools siguen abiertas.');
      } else {
        console.log('Las DevTools están cerradas.');
      }
    }, 1000);

    // Detiene el intervalo si las DevTools están abiertas
    window.addEventListener('blur', () => {
      if (devtoolsOpen) {
        clearInterval(checkDevTools);
        console.log('Detenido el chequeo de DevTools.');
      }
    });
  }
}
