import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeUFComponent } from './home-uf.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { PasosModule } from '../fletes/pasos/pasos.module';
import { ProfileModule } from '../profile/profile.module';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [HomeUFComponent],
  imports: [
    CommonModule,
    ComponentsModule,
    PasosModule,
    ProfileModule,
    FormsModule,
    IonicModule,
  ],
  exports: [
    HomeUFComponent 
  ]
})
export class HomeUFModule { }
