import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomePage } from './folder/home/home.page';
import { FletesPage } from './folder/fletes/fletes.page';
// import { RegistrarsePage } from './folder/registrarse/registrarse.page';
import { CommonModule } from '@angular/common';
import { HomeUFComponent } from './folder/home-uf/home-uf.component';
import { RegistrarseComponent } from './folder/registrarse/registrarse.component';
import { Paso1fComponent } from './folder/registrarse/flete/paso1f/paso1f.component';
import { Paso1UComponent } from './folder/registrarse/user/paso1-u/paso1-u.component';
import { ProfileComponent } from './folder/profile/profile.component';
import { HomeUserComponent } from './components/ComponentesUsuario/home-user/home-user.component';
import { ProfileUsuarioComponent } from './components/ComponentesUsuario/profile-usuario/profile-usuario.component';
import { FletesDisComponent } from './folder/fletes/fletes-dis/fletes-dis.component';
import { CardComponent } from './folder/fletes/fletes-dis/card/card.component';
import { PreciosComponent } from './folder/fletes/pasos/precios/precios.component';
import { PedidosFinalizadosComponent } from './folder/fletes/pasos/precios/pedidos-finalizados/pedidos-finalizados.component';
import { Paso1Component } from './folder/fletes/pasos/paso1/paso1.component';
import { IniciarAppComponent } from './components/ComponentesFleteros/iniciar-app/iniciar-app.component';
import { LoginComponent } from './folder/login/login.component';
import { AngularFireAuthGuard } from '@angular/fire/compat/auth-guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    component: HomeUFComponent
  },
  {
    path: 'fletes',
    component: FletesPage
  },
  {
    path: 'registrarse',
    component: RegistrarseComponent
  },
  {
    path: 'paso1F',
    component: Paso1fComponent
  },
  // {
  //   path: 'paso4F',
  //   component: Paso4fComponent,
  // },
  {
    path: 'paso1U',
    component: Paso1UComponent
  },
  {
    path: 'paso1',
    component: Paso1Component
  },
  {
    path: 'iniciarApp',
    component: IniciarAppComponent
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  
  // {
  //   path: 'regi',
  //   component: RegistrarsePage,
  // },

  {
    path: 'profile',
    component: ProfileComponent,
  },
  {
    path: 'homeUser',
    component: HomeUserComponent, canActivate: [AngularFireAuthGuard]
  },
  {
    path: 'profile-User',
    component: ProfileUsuarioComponent, canActivate: [AngularFireAuthGuard]
  },
  {
    path: 'fletesDis',
    component: FletesDisComponent, canActivate: [AngularFireAuthGuard]
  },
  {
    path: 'card',
    component: CardComponent, canActivate: [AngularFireAuthGuard]
  },
  {
    path: 'precios',
    component: PreciosComponent, canActivate: [AngularFireAuthGuard]
  },
  {
    path: 'pedidosFinalizados',
    component: PedidosFinalizadosComponent, 
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}










