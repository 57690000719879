import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserF, UserU, datosVehiculo } from '../models/models';
import { AuthService } from '../services/auth.service';
import { FirestoreService } from '../services/firestore.service';
import { InteractionService } from '../services/interaction.service';
import { NuevoService } from '../services/nuevo.service';
import { AlertController, InfiniteScrollCustomEvent } from '@ionic/angular';
import { forkJoin, Observable } from 'rxjs';


@Component({
  selector: 'app-home-uf',
  templateUrl: './home-uf.component.html',
  styleUrls: ['./home-uf.component.scss'],
})
export class HomeUFComponent implements OnInit {

  login: boolean = false;
  rol: 'Usuario' | 'Fletero' | 'Admin' = null; // O usa 'string | null'
  //ADMIN
  menuVisible: boolean = false;
  itemsU = [];
  itemsF: any[] = []; // Asegúrate de tener esta propiedad
  // itemsF = [];
  DatoVehicular: datosVehiculo;
  valueSelected: number = 1; // Cambiado a tipo number
  filteredItemsF = [];
  dniSearch: string = ''; // Variable para la búsqueda


  constructor( private auth: AuthService,
               private router: Router,
               private interaction: InteractionService,
               private firestore: FirestoreService,
              private database: NuevoService,
              private alertController: AlertController // Inyecta AlertController

               
    ) {      this.auth.stateUser().subscribe( res => {
      if (res) {
           console.log('está logeado');
           this.login = true;
           this.getDatosUser(res.uid);
           this.getDatosFletero(res.uid);
           this.getFletero(); // Asegúrate de cargar los datos al iniciar

      } else {
        console.log('no está logeado');
        this.login = false;
       this.router.navigate(['/login'])
        
      }   
 })}

  ngOnInit() {

  }

  
  // segmentChanged(event: Event) {
  //   const customEvent = event.target as HTMLIonSegmentElement;
  //   this.valueSelected = parseInt(customEvent.value, 10); // Convert the value to number
  //   console.log(this.valueSelected);
  // }
  


onIonInfinite(ev) {
  this.generateItems();
  setTimeout(() => {
    (ev as InfiniteScrollCustomEvent).target.complete();
  }, 500);
}

private generateItems() {
  const count = this.itemsU.length + 1;
  for (let i = 0; i < 50; i++) {
    this.itemsU.push({ nombre: `Nombre ${count + i}`, apellido: `Apellido ${count + i}`, dni: '00000000', domicilio: 'Dirección', telefono: '00000000', edad: 25 });
  }
}

showOptionsMenu(itemFlete: any) {
  this.itemsF.forEach(item => {
    if (item !== itemFlete) {
      item.showOptions = false; // Ocultar otros menús
    }
  });
  itemFlete.showOptions = !itemFlete.showOptions; // Alternar el estado del menú seleccionado
}


onRowClick(itemFlete: any) {
  if (!itemFlete.habilitado) { // Verifica si 'habilitado' es false
    this.showAlert(itemFlete);
  }
}


async showAlert(itemFlete: any) {
  const alert = await this.alertController.create({
    header: 'Fletero No Habilitado',
    message: `El fletero ${itemFlete.nombre} ${itemFlete.apellido} no está habilitado. ¿Deseas habilitarlo?`,
    buttons: [
      {
        text: 'Cancelar',
        role: 'cancel',
        handler: () => {
          console.log('Habilitación cancelada');
        }
      },
      {
        text: 'Habilitar',
        handler: () => {
          this.cambiarVerificacion(itemFlete); // Aquí puedes implementar la lógica de habilitación
        }
      }
    ]
  });

  await alert.present();
}

async testAlert() {
  const alert = await this.alertController.create({
    header: 'Test Alert',
    message: 'This is a test alert.',
    buttons: ['OK']
  });

  await alert.present();
}
  getDatosUser(uid: string) {
    const path = `Usuarios/${uid}/DatosPersonales`;
    const id = uid;
    this.firestore.getDoc<UserU>(path, id).subscribe( res => {
        // console.log('datos -> ', res);
        if (res) {
          this.rol = res.perfil
        }
    })
  }

  getDatosFletero(uid: string) {
    const path = 'Fleteros';
    const id = uid;
    this.firestore.getDoc<UserF>(path, id).subscribe( res => {
        // console.log('datos -> ', res);
        if (res) {
          this.rol = res.perfil
        }
    })
}



//ADMIN


// async getUser() {
//   // Llama al método 'getAll' del servicio para obtener la lista de usuarios
//   this.database.getAll('Usuarios').then((res: Observable<any[]>) => {
//     res.subscribe(resRef => {
//       // Crear un array para almacenar los datos de los usuarios
//       this.itemsU = [];

//       // Iterar sobre cada usuario para obtener su ID
//       resRef.forEach(userRef => {
//         // Obtener el ID del usuario
//         const userId = userRef.payload.doc.id;

//         // Utilizar el método 'getById' del servicio para obtener los detalles del usuario desde la subcolección 'DatosPersonales'
//         this.database.getById(`Usuarios/${userId}/DatosPersonales`, userId).then((docSnapshot: any) => {
//           // Suscribirse al observable para acceder a los datos del usuario
//           docSnapshot.subscribe((userDetailsDoc: any) => {
//             const userDetails = userDetailsDoc.data();
//             if (userDetailsDoc.data()) {
//               // Agregar los detalles del usuario al array
//               this.itemsU.push({
//                 ...userDetails,
//                 id: userId,
//                 showOptions: false
//               });
//             }
//           });
//         }).catch(error => console.error('Error fetching user details:', error));
//       });
//     });
//   }).catch(error => console.error('Error fetching users:', error));
// }




getFletero(){
  this.database.getAll(`Fleteros`).then(res =>{
    res.subscribe(resRef=>{
       
      this.itemsF = resRef.map(pasosRef =>{
        let pasosFlete = pasosRef.payload.doc.data();
        pasosFlete['id'] = pasosRef.payload.doc.id;
        pasosFlete['showOptions'] = false; // Inicializar showOptions en false
        return pasosFlete;
      })
      console.log(this.itemsF);
      this.filteredItemsF = [...this.itemsF]; // Inicializa filteredItemsF con todos los items

    })
  })
}



cambiarVerificacion(itemFlete) {
  // Alterna el valor de 'habilitado'
  const verificado = !itemFlete.verificado;
  
  // Ruta del documento en Firestore
  const documentPath = `Fleteros/${itemFlete.id}`;
  
  // Datos a actualizar
  const data = { verificado: verificado };

  // Actualiza el fletero en la base de datos
  this.database.updateDocument(documentPath, data)
    .then(() => {
      console.log('Fletero actualizado:', itemFlete);
      // Actualiza el objeto local para reflejar el cambio
      itemFlete.verificado = verificado;
    })
    .catch(error => {
      console.error('Error al actualizar el fletero:', error);
    });
}

 // Filtra los fleteros por DNI
filterFleteros() {
  const searchTerm = this.dniSearch.toLowerCase();
  this.filteredItemsF = this.itemsF.filter(fletero => {
    // Asegúrate de que fletero.dni sea una cadena y maneja casos undefined o null
    const dni = fletero.dni ? fletero.dni.toLowerCase() : '';
    return dni.includes(searchTerm);
  });
}


clearSearch() {
  this.dniSearch = '';
  this.filterFleteros(); // Reaplicar el filtro para mostrar todos los resultados
}
}
